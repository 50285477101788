import { render, staticRenderFns } from "./StudentViewScriptCreation.vue?vue&type=template&id=345d413a&scoped=true"
import script from "./StudentViewScriptCreation.vue?vue&type=script&lang=js"
export * from "./StudentViewScriptCreation.vue?vue&type=script&lang=js"
import style0 from "./StudentViewScriptCreation.vue?vue&type=style&index=0&id=345d413a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "345d413a",
  null
  
)

export default component.exports